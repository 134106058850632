@use "sass:math";
@use "sass:map";

// Colors variables
$colors: (
  "black": #000,
  "white": #fff,
  "primary-100": #F1F7FC,
  "primary-200": #DFF2FB,
  "primary": #11ABEC,
  "primary-450": #0DA1DF,
  "primary-500": #1093CB,
  "primary-700": #013A51,
  "primary-900": #012B3C,
  "blue-gray": #7995B4,
  "gray-100": #F9FBFC,
  "gray-200": #F3F7FC,
  "gray-300": #DFE8F2,
  "gray-325": #A8BFD7,
  "gray-350": #516A87,
  "gray": #354A61,
  "gray-700": #0B1C32,
  "green": #0ED094,
  "green-100": #F2FDFB,
  "green-200": #CFF6EA,
  "green-300": #A7DF17,
  "green-500": #00BB81,
  "green-700": #06533B,
  "turquoise": #A0E3DD,
  "red-200": #FFE3DF,
  "red-100": #FEF3F1,
  "red": #FD755F,
  "red-500": #F15035,
  "red-700": #712519,
  "yellow-100": #FFF7E8,
  "yellow-200": #FFF5BF,
  "yellow-300": #FFD951,
  "yellow": #FAA11C,
  "yellow-500": #FA9802,
  "yellow-700": #69440C,
  "purple-100": #F6F4FF,
  "purple-200": #EAE6FF,
  "purple-300": #D6CDFF,
  "purple": #5843BE,
  "purple-700": #2F236C,
  "pink": #DD5CA2,
  "qonto-black": #1D1D1B,
);
$colors-dark-mode: (
  "black": #000,
  "white": #0B1B2E,
  "primary-100": #1A2A42,
  "primary-200": #253856,
  "primary": #11ABEC,
  "primary-450": #1093CB,
  "primary-500": #1093CB,
  "primary-700": #D4D4D4,
  "blue-gray": #8DA6C1,
  "gray-100": #071221,
  "gray-200": #07121F,
  "gray-300": #364759,
  "gray-325": #637385,
  "gray-350": #B4C3D4,
  "gray": #E3EFFC,
  "gray-700": #EFF5FC,
  "green": #0ED094,
  "green-100": #082B24,
  "green-200": #1F473C,
  "green-300": #688C0D,
  "green-500": #00BB81,
  "green-700": #0ED094,
  "turquoise": #29837B,
  "red-100": #410A02,
  "red-200": #593732,
  "red": #FD755F,
  "red-500": #F15035,
  "red-700": #FD755F,
  "yellow-100": #453F27,
  "yellow-200": #595232,
  "yellow-300": #FFD951,
  "yellow": #FAA11C,
  "yellow-500": #FA9802,
  "yellow-700": #FAA11C,
  "purple-100": #1B033A,
  "purple-200": #1D1155,
  "purple-300": #633D8F,
  "purple": #AB75F0,
  "purple-700": #AB75F0,
  "pink": #DD5CA2,
  "qonto-black": #1D1D1B,
);

// 8pt grid
$size: 8;
$spacers: (
  auto: auto,
  0: 0,
  4: math.div($size, 2),
  8: $size,
  12: $size * 1.5,
  16: $size * 2,
  24: $size * 3,
  32: $size * 4,
  40: $size * 5,
  48: $size * 6,
  56: $size * 7,
  64: $size * 8,
  72: $size * 9,
  80: $size * 10
);

// Font variables
$font-title: "Open Sans";
$font-title-secondary: "Lexend";
$font-body: "Open Sans";
$font-qonto: "Polysans_Qonto_Slim";
$h1-size: 32;
$h2-size: 26;
$h3-size: 22;
$h4-size: 18;
$h5-size: 16;
$h6-size: 14;
$body-size: $size * 2;

// Layout variables
$sidebar-width: 240px;
$sidebar-compact-width: 64px;
$body-width: 1170px;
$control-panel-width: 300px;
$control-panel-large-width: 400px;
$safe-area-padding: 60;

// Breakpoints
$breakpoints: (
  small: 0,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  xxlarge-custom: 1400,
  xxlarge: 1441,
  xxxlarge: 1600,
) !default;
$container-lg-width: 1194px;
$container-md-width: 960px;
$container-sm-width: 680px;

////////////////////
// GRID
////////////////////////////////////////////////////////////////////////////////
$column-gutter: map.get($spacers, 8) * 1px;
$grid-number: 12;

////////////////////
// MEDIA QUERIES
////////////////////////////////////////////////////////////////////////////////
$screen: "only screen" !default;
$small-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'small') * 1px})" !default;
$small-only: "#{$screen} and (min-width: #{map.get($breakpoints, 'small') * 1px}) and (max-width: #{map.get($breakpoints, 'medium') * 1px})" !default;
$medium-down: "#{$screen} and (max-width: #{calc(map-get($breakpoints, 'medium') - 1) * 1px})" !default;
$medium-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'medium') * 1px})" !default;
$medium-only: "#{$screen} and (min-width: #{map.get($breakpoints, 'medium') * 1px}) and (max-width: #{map.get($breakpoints, 'large') * 1px})" !default;
$large-down: "#{$screen} and (max-width: #{calc(map-get($breakpoints, 'large') - 1) * 1px})" !default;
$large-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'large') * 1px})" !default;
$large-only: "#{$screen} and (min-width: #{map.get($breakpoints, 'large') * 1px}) and (max-width: #{map.get($breakpoints, 'xlarge') * 1px})" !default;
$xlarge-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'xlarge') * 1px})" !default;
$xlarge-down: "#{$screen} and (max-width: #{calc(map-get($breakpoints, 'xlarge') - 1) * 1px})" !default;
$xlarge-only: "#{$screen} and (min-width: #{map.get($breakpoints, 'xlarge') * 1px}) and (max-width: #{map.get($breakpoints, 'xxlarge') * 1px})" !default;
$xxlarge-custom-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'xxlarge-custom') * 1px})" !default;
$xxlarge-custom-down: "#{$screen} and (max-width: #{calc(map-get($breakpoints, 'xxlarge-custom') - 1) * 1px})" !default;
$xxlarge-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'xxlarge') * 1px})" !default;
$xxxlarge-up: "#{$screen} and (min-width: #{map.get($breakpoints, 'xxxlarge') * 1px})" !default;
$xxxlarge-down: "#{$screen} and (max-width: #{map.get($breakpoints, 'xxxlarge') * 1px})" !default;

