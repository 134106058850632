@import "../abstracts/index";

.dougs-tabs {
  position: relative;
  display: block;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-300);
  }

  @media #{$large-down} {
    margin: 0 -16px;
  }

  .scroll-container {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media #{$large-down} {
      padding: 0;
      width: 100%;
      margin: 0;
    }

    &.dragging {
      cursor: grab;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

[dougs-tab] {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px 8px;
  text-decoration: none;
  font-size: rem-calc(14);
  color: var(--gray);
  white-space: nowrap;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: transparent;
    transition: 200ms ease-in-out;
  }

  &:hover:not(.active)::after {
    background-color: rgb(var(--gray-700-rgb) / 40%);
  }

  &.active {
    color: var(--primary-700);

    &::after {
      background-color: var(--primary-700);
    }
  }


  &.admin {
    color: var(--purple);

    &.active::after {
      background-color: var(--purple);
    }
  }
}

.tabs-pagination {
  z-index: 2;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: flex-start;

  &.white-background {
    background-color: var(--white);
  }

  &.gray-background {
    background-color: var(--gray-200);
  }

  &.start {
    left: 0;
    top: 0;
    box-shadow: 12px 0 15px -10px rgb(var(--black-rgb) / 30%);
  }

  &.end {
    text-align: end;
    right: 0;
    top: 0;
    box-shadow: -12px 0 15px -10px rgb(var(--black-rgb) / 30%);
  }

  i {
    padding: 4px;
    cursor: pointer;

    &:hover {
      outline: 1px solid var(--gray-300);
      border-radius: 4px;
    }
  }

  &.hidden {
    display: none;
  }
}
