@import '../abstracts';

.invoices-body {
  width: 100%;
  max-width: $body-width;
  padding: 24px 60px;

  &.no-padding-top {
    padding: 0 60px 24px;
  }

  &.auto-margin {
    margin: auto;
  }
}

.invoices-header {
  width: 100%;
  background-color: var(--white);
}