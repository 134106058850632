@use "sass:map";
@use "sass:color";
@import '../abstracts';

button {
  &.primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary-450);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--primary-500);
      border-color: var(--primary-500);
    }

    &:disabled {
      background-color: var(--gray-300);
      border-color: var(--gray-300);
      color: var(--gray-350);
    }
  }

  &.primary-reverse {
    color: var(--primary);
    background-color: transparent;
    border-color: var(--primary);

    &:focus,
    &:active,
    &:hover {
      color: var(--white);
      background-color: var(--primary);
      border-color: transparent;
    }

    &:disabled {
      background-color: var(--gray-300);
      border-color: var(--gray-300);
      color: var(--gray-350);
    }
  }

  &.dark-primary {
    color: var(--white);
    background-color: var(--primary-700);
    border-color: var(--primary-700);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--primary-900);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.admin {
    color: var(--white);
    background-color: var(--purple);
    border-color: var(--purple);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--purple);
      border-color: var(--purple);
    }

    &:disabled {
      background-color: var(--purple-300);
      border-color: var(--purple-300);
      color: var(--gray-325);
    }
  }

  &.purple {
    color: var(--white);
    background-color: var(--purple);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--purple);
      border-color: var(--purple);
    }

    &:disabled {
      background-color: rgb(var(--purple-rgb) / 20%);
      border-color: rgb(var(--purple-rgb) / 0%);
    }
  }

  &.gray {
    color: var(--primary-700);
    background-color: var(--gray-200);
    border-color: var(--gray-200);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray-200);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.warning {
    color: var(--white);
    background-color: var(--yellow);
    border-color: color.scale(#faa11c, $lightness: -5%);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--yellow-500);
      border-color: var(--yellow-500);
    }

    &:disabled {
      background-color: rgb(var(--yellow-rgb) / 20%);
      border-color: rgb(var(--yellow-rgb) / 0%);
    }
  }

  &.secondary {
    color: var(--primary-700);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--gray);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.primary-light {
    color: var(--primary-700);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }

    &:focus,
    &:active,
    &:hover {
      border-color: var(--primary);
    }
  }

  &.primary-extra-light {
    color: var(--primary);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }

    &:focus,
    &:active,
    &:hover {
      border-color: var(--primary);
    }
  }

  &.success-light {
    color: var(--green);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--green);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.delete-light {
    color: var(--red);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--red);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.warning-light {
    color: var(--yellow);
    background-color: var(--white);
    border-color: var(--gray-300);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--yellow);
    }

    &:disabled {
      color: var(--gray-350);
      background-color: var(--gray-300);
    }
  }

  &.success {
    color: var(--white);
    background-color: var(--green);
    border-color: color.scale(#0ed094, $lightness: -5%);

    &:focus,
    &:active,
    &:hover {
      border-color: var(--green-500);
      background-color: var(--green-500);
    }

    &:disabled {
      background-color: rgb(var(--green-rgb) / 20%);
      border-color: rgb(var(--green-rgb) / 0%);
    }
  }

  &.delete {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);

    &:focus,
    &:active,
    &:hover {
      background-color: var(--red-500);
      border-color: var(--red-500);
    }

    &:disabled {
      background-color: rgb(var(--red-rgb) / 20%);
      border-color: rgb(var(--red-rgb) / 0%);
    }
  }

  &.ghost {
    color: var(--primary-700);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: rgb(var(--gray-700-rgb) / 10%);
      border-color: transparent;
    }

    &:disabled {
      background-color: transparent;
      border-color: transparent;
      color: var(--gray-325);
    }
  }
}